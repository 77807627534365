<template>
  <div class="home">
    <van-nav-bar class="van-nav-bar--fixed" title="六合统计" left-arrow  right-text="开奖记录"  @click-left="onClickLeft"  @click-right="onClickRight"></van-nav-bar>
        <div data-v-d94b90a8="" class="mint-tab-container-wrap mint-tab-container">
    
        <div data-v-d94b90a8="" class="mint-tab-container-item">
            <div data-v-d94b90a8="" class="container-wrap">
                <div data-v-d94b90a8="" class="top-wrap">
                    <h4 data-v-d94b90a8="">特码出现期数最多的号码</h4>
                    <div data-v-d94b90a8="" class="color-wrap">
                        <div data-v-d94b90a8="" :class="item.bose_name=='蓝波'?'blue':item.bose_name=='红波'?'red':item.bose_name=='绿波'?'green':''" v-for="item in list1" :key="item.id">{{ item.haoma_name }}</div>
                    </div>
                </div>
                <div data-v-d94b90a8="" class="top-wrap">
                    <h4 data-v-d94b90a8="">特码当前遗漏期数最多的号码</h4>
                    <div data-v-d94b90a8="" class="color-wrap">
                        <div data-v-d94b90a8="" :class="item.bose_name=='蓝波'?'blue':item.bose_name=='红波'?'red':item.bose_name=='绿波'?'green':''" v-for="item in list2" :key="item.id">{{ item.haoma_name }}</div>
                    </div>
                </div>
                <div data-v-d94b90a8="" class="top-wrap">
                    <h4 data-v-d94b90a8="">正码出现期数最多的号码</h4>
                    <div data-v-d94b90a8="" class="color-wrap">
                        <div data-v-d94b90a8="" :class="item.bose_name=='蓝波'?'blue':item.bose_name=='红波'?'red':item.bose_name=='绿波'?'green':''" v-for="item in list3" :key="item.id">{{ item.haoma_name }}</div>
                    </div>
                </div>
                <div data-v-d94b90a8="" class="top-wrap">
                    <h4 data-v-d94b90a8="">正码当前遗漏期数最多的号码</h4>
                    <div data-v-d94b90a8="" class="color-wrap">
                        <div data-v-d94b90a8="" :class="item.bose_name=='蓝波'?'blue':item.bose_name=='红波'?'red':item.bose_name=='绿波'?'green':''" v-for="item in list4" :key="item.id">{{ item.haoma_name }}</div>
                    </div>
                </div>
            </div>
            <div data-v-d94b90a8="" class="container-wrap">
                <div data-v-d94b90a8="" class="bottom-wrap">
                    <div data-v-d94b90a8="">特码出现期数最多的生肖</div>
                    <div data-v-d94b90a8="">
                        <div data-v-d94b90a8="" v-for="item in list5" :key="item.id">{{ item.animal_name }}</div>
                    </div>
                </div>
                <div data-v-d94b90a8="" class="bottom-wrap">
                    <div data-v-d94b90a8="">特码当前遗漏期数最多的生肖</div>
                    <div data-v-d94b90a8="">
                        <div data-v-d94b90a8="" v-for="item in list6" :key="item.id">{{ item.animal_name }}</div>
                    </div>
                </div>
                <div data-v-d94b90a8="" class="bottom-wrap">
                    <div data-v-d94b90a8="">正码出现期数最多的生肖</div>
                    <div data-v-d94b90a8="">
                        <div data-v-d94b90a8="" v-for="item in list7" :key="item.id">{{ item.animal_name }}</div>
                    </div>
                </div>
                <div data-v-d94b90a8="" class="bottom-wrap">
                    <div data-v-d94b90a8="">正码当前遗漏期数最多的生肖</div>
                    <div data-v-d94b90a8="">
                        <div data-v-d94b90a8="" v-for="item in list8" :key="item.id">{{ item.animal_name }}</div>
                    </div>
                </div>
            </div>
            <div data-v-d94b90a8="" class="container-wrap">
                <div data-v-d94b90a8="" class="bottom-wrap">
                    <div data-v-d94b90a8="">特码出现期数最多的波色</div>
                    <div data-v-d94b90a8="" class="red-text">
                        <div data-v-d94b90a8="" :class="item.bose_name=='蓝波'?'blue':item.bose_name=='红波'?'red':item.bose_name=='绿波'?'green':''" v-for="item in list9" :key="item.id">{{ item.bose_name }}</div>
                    </div>
                </div>
                <div data-v-d94b90a8="" class="bottom-wrap">
                    <div data-v-d94b90a8="">特码当前遗漏期数最多的波色</div>
                    <div data-v-d94b90a8="" class="red-text">
                        <div data-v-d94b90a8="" :class="item.bose_name=='蓝波'?'blue':item.bose_name=='红波'?'red':item.bose_name=='绿波'?'green':''" v-for="item in list10" :key="item.id">{{ item.bose_name }}</div>
                    </div>
                </div>
                <div data-v-d94b90a8="" class="bottom-wrap">
                    <div data-v-d94b90a8="">正码出现期数最多的波色</div>
                    <div data-v-d94b90a8="" class="red-text">
                        <div data-v-d94b90a8="" :class="item.bose_name=='蓝波'?'blue':item.bose_name=='红波'?'red':item.bose_name=='绿波'?'green':''" v-for="item in list11" :key="item.id">{{ item.bose_name }}</div>
                    </div>
                </div>
                <div data-v-d94b90a8="" class="bottom-wrap">
                    <div data-v-d94b90a8="">正码当前遗漏期数最多的波色</div>
                    <div data-v-d94b90a8="" class="red-text">
                        <div data-v-d94b90a8="" :class="item.bose_name=='蓝波'?'blue':item.bose_name=='红波'?'red':item.bose_name=='绿波'?'green':''" v-for="item in list12" :key="item.id">{{ item.bose_name }}</div>
                    </div>
                </div>
            </div>
            <div data-v-d94b90a8="" class="container-wrap" style="padding-bottom: 80px;">
                <div data-v-d94b90a8="" class="bottom-wrap">
                    <div data-v-d94b90a8="">特码出现期数最多的尾数</div>
                    <div data-v-d94b90a8="">
                        <div data-v-d94b90a8="" v-for="item in list13" :key="item.id">{{ item.mantissa_name }}</div>
                    </div>
                </div>
                <div data-v-d94b90a8="" class="bottom-wrap">
                    <div data-v-d94b90a8="">特码当前遗漏期数最多的尾数</div>
                    <div data-v-d94b90a8="">
                        <div data-v-d94b90a8="" v-for="item in list14" :key="item.id">{{ item.mantissa_name }}</div>
                    </div>
                </div>
            </div>
        </div>
         
    </div>
  </div>
</template>

<script>
 
export default {
  name: 'Statistics',
  data() {
    return {
      year_id:1,
      lotto_id:2,
      active: 'statistics',
      list1:[],
      list2:[],
      list3:[],
      list4:[],
      list5:[],
      list6:[],
      list7:[],
      list8:[],
      list9:[],
      list10:[],
      list11:[],
      list12:[],
      list13:[],
      list14:[],
      loading: false,
      finished: false,
      iserror:false,
      refreshing:false,
      page:0,
      message:''
    };
  },
  mounted() {
        this.lotto_id= parseInt(this.$route.query.lotto_id) ? parseInt(this.$route.query.lotto_id)  : 2;
        this.getData();
    },
  methods: {
        onClickLeft() {
            // 返回上一级页面
            this.$router.back();
        },
        onClickRight() {
            this.$router.push({ path: '/kaijiang'});
        },
        getData() {
            this.$http
                .get("api/statistics/index?lotto_id="+this.lotto_id)
                .then(function (response) {
                   
                    if (response.data.code == "ok") {
                        this.list1 = response.data.data.list1;
                        this.list2 = response.data.data.list2;
                        this.list3 = response.data.data.list3;
                        this.list4 = response.data.data.list4;
                        this.list5 = response.data.data.list5;
                        this.list6 = response.data.data.list6;
                        this.list7 = response.data.data.list7;
                        this.list8 = response.data.data.list8;
                        this.list9 = response.data.data.list9;
                        this.list10 = response.data.data.list10;
                        this.list11 = response.data.data.list11;
                        this.list12 = response.data.data.list12;
                        this.list13 = response.data.data.list13;
                        this.list14 = response.data.data.list14;
                    } else {
                        this.iserror = true;
                        this.message = response.data.msg;
                    }
                    this.loading = false;
                    //console.log(this.list);
                });
    
        }
    }
}
</script>
